.fixedPesquisa {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 15px;
    position: fixed;
    background-color: white;
}
.col-modulo {
    left: 0;
    top: 87px;
    z-index: 1;
    width: 100%;
    position: fixed;
    background-color: white;
    .rowPanel { 
        padding-top: 15px;
        overflow-x: hidden;
        height: calc(100vh - 129px);
        margin-bottom: 0 !important;
        margin-top: -16px !important;
    }
    span {
        font-size: 14px;
    }
    .ant-col {
        a {
            white-space: pre-wrap;
            img {
                width: 65px;
                opacity: 0.5;
                align-self: center;
                margin-bottom: 10px;
            }
            b {
                line-height: 15px;
            }
        }
    }
}
@primary-color: #0053a6;@link-color: #0053a6;@text-color: #656565;@font-size-base: 12px;@border-color-base: #ced4da;@disabled-color: rgba(0, 0, 0, 0.2);