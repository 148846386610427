/************* CUSTOM ***************/
.iframe {
    min-height: 700px;
    width: 100%;
}

.error {
    color: #ff4d4f;
}

label {
    margin-bottom: 5px;
    color: @opacity-85;
    display: inline-block;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.float-right {
    float: right;
}

.minheight-table {
    min-height: 465px;
}

.textEmpty {
    color: #000;
    margin: 15px;
}

.ant-input,
input,
.ant-select-selection-item,
.ant-select-selection-placeholder {
    font-weight: 500;
}

.flexContent {
    display: flex;
}

.flexDirectionColumn {
    flex-direction: column;
}

.flexDirectionRowReverse {
    flex-direction: row-reverse;
}

.justifyContentCenter {
    justify-content: center;
}

.justifyContentBetween {
    justify-content: space-between;
}

.vh100 {
    overflow: hidden;
    height: 100vh !important;
}

.p-10 {
    padding: 10px;
}

.p-t-28 {
    padding-top: 28px !important;
}

.f-12 {
    font-size: 12px !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-16 {
    font-size: 16px !important;
}

.f-18 {
    font-size: 18px !important;
}

.f-25 {
    font-size: 25px !important;
}

.m-0 {
    margin: 0 !important;
}

.m-t-0 {
    margin-top: 0px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-22 {
    margin-top: 22px !important;
}

.m-t-60 {
    margin-top: 60px;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-5 {
    margin-bottom: 5 !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-r-8 {
    margin-right: 8px;
}

.p-0-15 {
    padding: 0 15px;
}

.col-layout {
    padding: 10px;
}

.right {
    float: right;
}

.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.ant-tabs-nav-list {
    >.ant-tabs-tab {
        padding: 9px !important;
        margin: 0 1px 0 0 !important;
    }
}

.ant-select,
.ant-input-number,
.ant-upload.ant-upload-select {
    width: 100% !important;
}

.ant-input-number-input {
    text-align: right !important;
}

.ant-layout {
    background-color: @white  !important;
}

.pages-col {
    >.ant-tabs-card {
        >.ant-tabs-nav {
            .ant-tabs-tab {
                border-color: @border-color-base;
            }
        }

        >div {
            >.ant-tabs-nav {
                .ant-tabs-tab {
                    border-color: @border-color-base;
                }
            }
        }
    }

    .ant-tabs-top {
        >.ant-tabs-nav {
            &::before {
                border-color: @border-color-base;
            }
        }

        >div {
            >.ant-tabs-nav {
                &::before {
                    border-color: @border-color-base;
                }
            }
        }
    }

    .ant-tabs-bottom {
        >.ant-tabs-nav {
            &::before {
                border-color: @border-color-base;
            }
        }

        >div {
            &::before {
                border-color: @border-color-base;
            }
        }
    }
}

.site-layout {
    padding: 10px;
    background-color: @white;
    border-left: 1px solid @border-color-base;

    >.ant-layout-content {
        background-color: @white;
    }
}

.preco,
.condicional,
.valePresente,
.transportadores,
.notaFiscal {
    .ant-collapse {
        &.topDif {
            .ant-collapse-extra {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }
    }
}

.localizar-produto {
    text-align: center;
    padding-top: 22px !important;
}


.ant-collapse {
    &.topDif {
        >.ant-collapse-item {
            >.ant-collapse-header {
                padding: 5px 0 5px 16px;
            }
        }

        .ant-collapse-extra {
            margin-top: -37px;

            >button {
                z-index: 2;
                position: relative;
            }
        }

    }

    &.p-0 {
        >.ant-collapse-item {
            >.ant-collapse-header {
                padding: 0px !important;

                >.ant-collapse-extra {
                    float: left !important;
                }
            }
        }
    }
}


.ant-collapse-content-box {
    background-color: @gray-color;
    border: 1px solid @border-color-base;
}

.colDet {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 48px;
    position: absolute;
}

.ant-picker {
    width: 100%;
}

.ant-form-item {
    margin-bottom: 0 !important;
}

.ant-form-item-label {
    padding: 0 !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.i-Position {
    z-index: 1;
    float: right;
    margin-top: -20px;
    position: relative;
}

.ant-tabs-tab {
    margin: 0 12px 0 12px !important;
}

.ant-alert-info {
    background-color: #ebecec !important;
}

.ant-radio-wrapper {
    white-space: normal !important;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

/************************************/

.tabela {
    table {
        span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .ant-table-cell {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            div {
                overflow: hidden;
                white-space: nowrap;
                justify-items: center;
                text-overflow: ellipsis;
            }
        }

        .ant-table-cell-content {
            div {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .ant-table-cell-ellipsis {
            div {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    /*table {
        .ant-table-thead {
            tr {
                th {
                    background-color:white;
                }
            }
        }
        .ant-table-tbody {
            tr {
                background-color:white;
                &.ant-table-row {
                    background-color:white;
                    &:nth-child(odd) {
                        background-color: @gray-color;
                    }
                }
                &:nth-child(odd) {
                    background-color: @gray-color;
                }
            }
        }
    }  */
}

.img {
    >img {
        width: 100%;
        max-width: 80%;
    }
}

.avatar-uploader {
    img {
        width: 100%;
    }
}

.colorInp {
    .ant-input-suffix {
        margin: 0;

        >span {
            border: 0;
            padding: 0;
            width: 25px;
            height: 100%;
            margin-right: -5px;
        }
    }
}

.m-t-21 {
    margin-top: 21px;
}

.ant-form-item-label {
    >label {
        line-height: 11px;
    }
}

@media screen and (max-width: 1200px) {
    .t-0 {
        margin-top: 0;
    }
}

.ant-input-number-input {
    text-align: left !important;
}

.textarea {
    resize: none;
}

.inLine {
    display: block !important;
    height: 30px;
}

.title-dashboard {
    margin-left: 5px;
    padding-top: 5px;
    font-size: 1.5em;
}

.font-weight {
    font-weight: bold;
}

.primary-color {
    color: @primary-color;
}

.b-c-s {
    border: 1px solid #ced4da !important;
}

.ant-select-item-option-selected {
    background-color: #eaeaea !important;
}

.ant-table {
    color: #4e4e4e !important;
}

.ant-menu-item a {
    color: #4e4e4e !important;
}

.g-o-b {
    float: right;
    margin-top: -2px;
    margin-left: 2px;
}

.minify_bar {

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #c0c0c0;
    }
}

.rateNumber {
    font-size: 50px;
}

.clientDesc {
    font-size: 16px;
}

.clientTotal {
    font-size: 20px;
    font-weight: bold;
}

.p-t-27 {
    padding-top: 27px;
}

.rate {
    &1 {
        &::-webkit-progress-value {
            background: #ff6f31 !important;
        }
    }

    &2 {
        &::-webkit-progress-value {
            background: #ff9f02 !important;
        }
    }

    &3 {
        &::-webkit-progress-value {
            background: #ffcf02 !important;
        }
    }

    &4 {
        &::-webkit-progress-value {
            background: #9ace6a !important;
        }
    }

    &5 {
        &::-webkit-progress-value {
            background: #57bb8a !important;
        }
    }

}

progress {
    height: 20px !important;
    width: 200px !important;
    -webkit-appearance: none;
    margin-left: 12px;

    &::-webkit-progress-bar {
        background: #CDCDCD;
        border-radius: 50px;
        padding: 2px;
    }

    &::-moz-progress-bar {
        background: #CDCDCD;
        border-radius: 50px;
        padding: 2px;
    }

    &::-webkit-progress-value {
        border-radius: 50px;
    }
}

.clearH3 {
    margin-right: 70px;
    margin-bottom: 0px;
    padding-top: 0px;
}

.m-t-p-20 {
    margin-top: -20px;
}

.m-t-p-75 {
    margin-top: -79px;
}

.w-100-p {
    width: 100%;
}

.w-s-n {
    white-space: normal !important;
}

.t-59 {
    top: 59px !important;
}

.p-l-20 {
    padding-left: 20px !important;
}

svg {
    margin-bottom: 5px;
}

.switch {
    .ant-form-item {
        position: relative;
    }

    .ant-form-item-label {
        top: 25px;
        z-index: 2;
        left: 35px;
        position: absolute;
    }

    .ant-form-item-control {
        margin-top: 23px;
        position: relative;
    }
}

nav[role="navigation"] {
    visibility: hidden;
}

.n-d-s {
    float: right;
    bottom: 60px;
}

.positionBottom{
    position: absolute;
    bottom: 59px;
    right: 22px;
    width: 96%;
    background-color: white;
    padding: 10px;
}

.balloon{
    background-color: #e5e5e5;
    margin-top: 15px !important;
    padding: 8px;
}

.center-col{
    flex: 1;
    // overflow-x: scroll;    
    overflow: hidden;    
    margin-bottom: 100px;
}