@import '../../less/styles.less';

.fixed-left {
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    .colLogo {
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        padding: 15px;
        color: @white-color;
        position: absolute;
        a {
            color: @white-color;
        }
    }
    .col-banners {
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &:before {
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            content: '';
            height: 100vh;
            display: block;
            position: absolute;
            background-color: @opacity-5;
        } 
        .ant-carousel {
            height: 100vh;
            .slick-slider {  
              height: 100vh;
            }
            .slick-slide {
              overflow: hidden;
              text-align: center;
              height: 100vh !important;
            }
        }
    }
}

.fixed-right {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    position: fixed;
    &.bg-log {
        background-color: @white-color !important;
    }
}


@primary-color: #0053a6;@link-color: #0053a6;@text-color: #656565;@font-size-base: 12px;@border-color-base: #ced4da;@disabled-color: rgba(0, 0, 0, 0.2);